import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

const env = process.env.NODE_ENV;

const AnalyticsConfig = {
    test: {
        google: {
            trackingId: 'none',
        },
    },
    development: {
        google: {
            trackingId: 'none',
        },
    },
    production: {
        google: {
            trackingId: 'UA-150259946-5',
        },
    },
};

const trackingId = AnalyticsConfig[env]?.google?.trackingId;

const analytics = Analytics({
    app: 'kika-serp-page',
    plugins: [
        googleAnalytics({
            debug: env === 'development',
            trackingId,
        }),
    ],
});

export default analytics;
