import React from 'react';
import { Link } from '@reach/router';
import { Helmet } from 'react-helmet';

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Contact Search Brightly</title>
            </Helmet>
            <div className="container">
                <div className="header">
                    <h1 id="logo">Search Brightly</h1>
                </div>
                <div className="content">
                    <iframe
                        id="contact"
                        title="Contact Us"
                        width="640px"
                        height="480px"
                        src="https://forms.office.com/Pages/ResponsePage.aspx?id=a6PcRmfZykOo7rs1gg4_f1SrBNYA4WxIo2NzNSt3R2lUNkpLMzFLSUNYR01SQjFYTlJEQTZQOUpMMy4u&embed=true"
                        frameBorder="0"
                        marginWidth="0"
                        marginHeight="0"
                        allowFullScreen={true}
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        msallowfullscreen="true"
                    >
                        {' '}
                    </iframe>
                </div>
                <footer>
                    <Link to="/">Search Brightly</Link> | <Link to="/privacy">Privacy Policy</Link> |{' '}
                    <Link to="/terms">Terms of Service</Link> | <Link to="/contact">Contact Us</Link>
                </footer>
            </div>
        </>
    );
};

export default Contact;
