import React, { useState } from 'react';
import { Link } from '@reach/router';
import { Helmet } from 'react-helmet';

import { useQuery } from '@/hooks/useQuery';
import quicklinks from '@/config/quicklinks.json';
import CookieConsent from 'react-cookie-consent';

import './home.css';

const campaignId = 'hkhv7i7nNXHsmXQxmkmjQK';

const shortLink = u => {
    const encodedUrl = encodeURIComponent(u);
    return `https://go.lnk1a9.com/link/r?u=${encodedUrl}&campaign_id=hkhv7i7nNXHsmXQxmkmjQK`;
};

const pixel = (type, campaignId) => {
    return `https://track.mocha.global/?timestamp=${Date.now()}&ua=${navigator.userAgent}&url=${encodeURIComponent(
        'https://lander.srch0.com'
    )}&pixel_id=6spKsimcozBt3r19sBdQ6Y&campaign_id=${campaignId}&campaign_type=supply&e=${type}`;
};

const iconForUrl = url => {
    const { hostname } = new URL(url);
    const domain = hostname.replace('www.', '');

    return `https://assets.mocha.global/mobicast/image/upload/c_pad,h_120,q_90/logo/${domain}.png`;
};

const Home = () => {
    const params = useQuery('q');
    const sourceCID = useQuery('cid');

    const [search] = useState(params);

    const go = link => {
        fetch(pixel('imp'));
        window.location = link;
    };

    const performSearch = form => {
        const value = new FormData(form).get('q');
        fetch(pixel('search'));
        window.location = `https://results.myshoppingfeed.com/search/537/${encodeURIComponent(
            value
        )}/?&t=1&chnm=${campaignId}&chnm2=${sourceCID}`;
    };

    const onSearch = e => {
        e.preventDefault();
        performSearch(e.target);
    };

    return (
        <>
            <Helmet>
                <title>Search Brightly</title>
            </Helmet>
            <div className="container">
                <div className="header">
                    <h1 id="logo">Search Brightly</h1>
                    <h2 id="poweredby">
                        powered by<a href="https://www.myshoppingfeed.com">myshoppingfeed.com</a>
                    </h2>
                    <img src="./images/header.png" alt="header" />
                </div>
                <form className="search" onSubmit={onSearch} method="post" action="#">
                    <input
                        id="search_input_id"
                        name="q"
                        defaultValue={search}
                        className="search-input"
                        type="text"
                        placeholder="Search..."
                    />
                    <button type="submit" className="search-button">
                        Search
                    </button>
                </form>

                <div className="quicklinks">
                    {quicklinks.map((link, i) => (
                        <div key={i} className="item" onClick={() => go(shortLink(link.url))}>
                            <div className="icon">
                                <img src={iconForUrl(link.url)} alt={link.text} />
                            </div>
                            <div className="text">{link.label}</div>
                        </div>
                    ))}
                </div>
                <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
                <footer>
                    <Link to="/">Search Brightly</Link> | <Link to="/privacy">Privacy Policy</Link> |{' '}
                    <Link to="/terms">Terms of Service</Link> | <Link to="/contact">Contact Us</Link>
                </footer>
            </div>
        </>
    );
};

export default Home;
