import React from 'react';
import { Link } from '@reach/router';
import { Helmet } from 'react-helmet';

const Privacy = () => {
    return (
        <>
            <Helmet>
                <title>Search Brightly Privacy Policy</title>
            </Helmet>
            <div className="container">
                <div className="header">
                    <h1 id="logo">Search Brightly</h1>
                </div>
                <div className="content">
                    <h1 id="introduction">Introduction </h1>
                    <p>
                        This Privacy Policy explains what information we may collect through the Service, how such
                        information may be used and/or shared with others, how we safeguard it and how you may access
                        and control its use in connection with our marketing communications and activities.
                    </p>
                    <p>
                        Please read this Privacy Policy carefully, prior to using the Service, and/or accessing the
                        Site. By using the Service, and/or accessing the Site, you are acknowledging that you understand
                        and agree to the terms hereof as well as the Terms of Use set forth on the Site.
                    </p>
                    <p>
                        Generally, searchbrightly.com does not collect, store, use or disclose any data collected with
                        an intention to personally identify and/or contact a person such as: name, address, telephone
                        number or e-mail address except in specific instances, for example, when you asking to receive
                        further information regarding one of the products or other offers listed on the Site, or as set
                        forth in this Privacy Policy.
                    </p>
                    <p>
                        If you would like to go directly to “Your Privacy Rights, Choice and Access”, please go to
                        section 5 of this policy. If you would like to go directly to “Managing Cookies (“Opt-Out”)”,
                        please go to section 6 of this policy.
                    </p>
                    <h1 id="owner-and-data-controller">Owner and Data Controller</h1>
                    <p>
                        This privacy policy (“Privacy Policy”) applies to information collected through
                        searchbrightly.com (“we”, “us” or “our”), whether accessed via computer, mobile device or other
                        technology (collectively, the “Service”). searchbrightly.com a business brand of Mocha Holdings
                        LLC 43 West 29th Street, 4th Floor New York, NY 10001, USA. In regard to the processing of your
                        data, Mocha Holdings LLC acts as the controller.
                    </p>
                    <p>searchbrightly.com is an e-commerce site.</p>
                    <h1 id="a-special-notice-about-children">A Special Notice About Children</h1>
                    <p>
                        Children (under the age of 16) are not eligible to use the Platform unsupervised, and we ask
                        that children do not submit any personal information to us. If you are under the age of 16, you
                        may only use this Platform in conjunction with, and under the supervision of your parents or
                        guardians.
                    </p>
                    <h1 id="users-under-13-years-of-age">Users Under 13 Years of Age</h1>
                    <p>
                        Children under the age of 13 are not eligible to use the Platform at all. Only parents or legal
                        guardians can submit personal information to us about a child.
                    </p>
                    <p>
                        By visiting our Site and/or using our Services, or by including children under the age of 13
                        years old in our provided services, you are agreeing to the terms of this Privacy Policy and the
                        accompanying Terms of Use.
                    </p>
                    <p>
                        If we learn we have collected Personal Information from a User under the age of 13 without
                        parental consent, we will delete that information as quickly as possible.
                    </p>
                    <h1 id="how-to-contact-us">How to Contact Us</h1>
                    <p>
                        For all data privacy matters, please contact our Data Protection Office (DPO) at
                        dpo@mocha.global. Our EU representative under the GDPR is Mocha Operations (Ireland) Limited. In
                        regard to the processing of your data, Mocha Holdings LLC acts as the controller.
                    </p>
                    <h1 id="how-we-collect-your-information">How We Collect Your Information</h1>
                    <p>We collect personal data about you in the following instances:</p>
                    <ol type="a">
                        <li>
                            <p>
                                When you voluntary give it to us: We may collect information if you use the Service or
                                otherwise contact or request information from us;
                            </p>
                        </li>
                        <li>
                            <p>Other people give it to us:</p>
                        </li>
                    </ol>
                    <blockquote>
                        <p>
                            We may collect information from advertising and marketing partners in order to monitor,
                            manage and measure ad campaigns. We also may collect information that is publicly available;
                        </p>
                    </blockquote>
                    <ol start="3" type="a">
                        <li>
                            <p>We may collect browsing data automatically when you visit our Site.</p>
                        </li>
                    </ol>
                    <h1 id="which-information-we-collect-about-you">Which Information We Collect About You</h1>
                    <p>We may collect the following information from you:</p>
                    <p>
                        a) Contact information: We may ask you to provide information such as name, e-mail address and
                        phone number. If you do not want your Personal Information collected, please do not submit it.
                    </p>
                    <p>
                        b) Usage Information: May include, in part, browser type, operating system, device type, an
                        estimation of your geographic location associated with your IP address, the page served, the
                        time, referring URLs and other information normally transmitted in HTTP requests.
                    </p>
                    <p>
                        c) Device Information: We may also automatically collect the internet protocol (“IP “) address
                        or other identifier (“Device Identifier “) for each computer, mobile device, technology or other
                        device (collectively, “Device “) you use to access the Service.
                    </p>
                    <p>
                        d) Log files: As is true of most web sites, we may gather certain information automatically and
                        store it in log files. This information may include IP addresses, browser type, internet service
                        provider, referring/exit pages, operating system, date/time stamp and clickstream data. As is
                        true of most web sites, we may gather certain information automatically and store it in log
                        files.
                    </p>
                    <p>
                        This information may include IP addresses, browser type, internet service provider,
                        referring/exit pages, operating system, date/time stamp and clickstream data.
                    </p>
                    <h1 id="how-we-use-your-data">How We Use Your Data</h1>
                    <p>a) Reply to your inquiries and respond to your requests (legitimate interest)</p>
                    <p>
                        b) Usage Data: We collect this information to power our services and to inform our business
                        operations. This statistical data provides us with information about the use of the Service,
                        such as how many visitors visit a specific webpage on which the Service is used, how long they
                        stay on that page, the type of content on that page. (legitimate interest)
                    </p>
                    <p>
                        c) Device Identifiers: We collect this information to optimize our current services and to help
                        with troubleshooting. (legitimate interest) Device identifiers can also be used for media
                        attribution. (legitimate interest)
                    </p>
                    <p>
                        d) Log files: We use this information, which does not identify individual users, to detect
                        fraud, administer the site, to track users ’actions around the Site and to gather demographic
                        information about our user base as a whole. (legitimate interest)
                    </p>
                    <h1 id="how-we-disclose-personal-data">How We Disclose Personal Data</h1>
                    <p>We may disclose personal data to third parties in the following circumstances:</p>
                    <p>a) Our entities within the Mocha Global group of companies.</p>
                    <p>
                        b) Service Providers: We share Personal Data with some of our service providers such as Google
                        Firebase, subject to contract terms that limit their use of Personal Data. We may have service
                        providers that provide services on our behalf, such as media attribution, identity verification
                        services, website hosting, data analysis, marketing services, information technology, and
                        related infrastructure, customer service, email delivery, and auditing services. These service
                        providers may need to access Personal Data to perform their services. We authorize such service
                        providers to use or disclose Personal Data only to perform services on our behalf and will
                        always comply with legal requirements. We require such service providers to contractually commit
                        to protecting the security and confidentiality of Personal Data they process on our behalf. Our
                        service providers are located in the European Union and the United States of America.
                    </p>
                    <p>
                        c) Corporate Transactions: In the event that we enter into, or intend to enter into, a
                        transaction that alters the structure of our business, such as reorganization, merger, sale,
                        joint venture, assignment, transfer, change of control, or other disposition of all or any
                        portion of our business, assets or stock, we may share Personal Data with third parties in
                        connection with such transaction. Any other entity which buys us or part of our business will
                        have the right to continue to use your Personal Data, subject to the terms of this Privacy
                        Policy.
                    </p>
                    <p>d)  Advertising partners to inform them about conversion rates for analytic purposes.</p>
                    <p>
                        d) Compliance and Harm Prevention: We share Personal Data as we believe necessary: (i) to comply
                        with applicable laws such as GDPR, CCPA, and others; (ii) to enforce our contractual rights;
                        (iii) to protect the Services, rights, privacy, safety, and property of searchbrightly.com, you,
                        or others; and (iv) to respond to a request from courts, law enforcement agencies, regulatory
                        agencies, and other public and government authorities, which may include authorities outside
                        your country of residence.
                    </p>
                    <p>
                        Some of the service providers mentioned above may be located abroad and, in this case, we adopt
                        additional safeguards to guarantee an adequate level of protection for your personal data.
                    </p>
                    <h1 id="transfer-of-your-information">Transfer of Your Information</h1>
                    <p>
                        We from searchbrightly.com will take all steps reasonably necessary to ensure that your data is
                        treated securely and in accordance with this Privacy Policy and no transfer of your Personal
                        Data will take place to an organization or a country unless there are adequate controls in place
                        including the security of your data and other personal information.
                    </p>
                    <p>
                        In accordance with the GDPR, when we transfer your personal data out of the EEA, we ensure a
                        similar degree of protection is afforded to it by ensuring that we use specific controls
                        approved by the European Commission which give personal data the same protection it has in
                        Europe.
                    </p>
                    <h1 id="how-we-protect-your-information">How We Protect Your Information</h1>
                    <p>
                        To ensure that your personal information is secure, we communicate our privacy and security
                        guidelines to all our employees and business partners and strictly follow precautionary measures
                        regarding privacy within the company.
                    </p>
                    <p>
                        We strive to protect your personal information through physical, technical, and organizational
                        measures aimed at reducing the risks of loss, misuse, unauthorized access, disclosure, and
                        improper alteration of this data.
                    </p>
                    <p>
                        We make commercially reasonable efforts to ensure the security of our systems. However, please
                        note that this is not a guarantee that such information may not be accessed, disclosed, altered,
                        or destroyed by breach of any of our physical technical or managerial safeguards. If we learn of
                        a security systems breach, then we may attempt to notify you electronically so that you can take
                        appropriate protective steps. We may post a notice on our Site or through other forms of
                        communication.
                    </p>
                    <h1 id="how-long-we-retain-your-information">How Long We Retain Your Information</h1>
                    <p>
                        We have determined retention periods based on the purpose of the processing and the rules set
                        forth within the GDPR as well as the legal and business requirements. For example, the GDPR
                        requires us to remove data when it has served its purpose and is no longer of use. We review the
                        personal data we collect regularly to ensure that the personal data we have is up-to-date and is
                        not retained longer than needed or required by the regulation.
                    </p>
                    <p>
                        If you wish to have more detailed information about our retention times, please contact us at
                        dpo@mocha.global.
                    </p>
                    <h1 id="california-privacy-rights">California Privacy Rights</h1>
                    <p>
                        This section applies only to California consumers. The California Consumer Privacy Act (CCPA) is
                        a data protection law that ensures you have certain rights regarding the Personal Information we
                        collect or maintain about you. Please note these rights are not absolute, and there may be cases
                        when we decline your request as permitted by law.
                    </p>
                    <ul>
                        <li>
                            <p>
                                Right of Access: you have the right to request that we disclose what Personal
                                Information we have collected, used, and disclosed about you in the past 12 months.
                            </p>
                        </li>
                        <li>
                            <p>
                                Right of Deletion: you have the right to request that we delete Personal Information
                                collected or maintained by us, subject to certain exceptions.
                            </p>
                        </li>
                        <li>
                            <p>
                                Right to Non-Discrimination: You have the right to not be discriminated against by us
                                because you exercised any of your rights under the CCPA.
                            </p>
                        </li>
                    </ul>
                    <p>
                        You also have the right to opt-out of the sale of personal information. In the preceding 12
                        months, we have not disclosed your personal information to any third party in a manner that
                        would be considered a sale under the CCPA.
                    </p>
                    <p>
                        For more details on the personal information collected in the preceding 12 months, see our
                        sections above How We Collect Your Information and Which Information We Collect About You. We
                        collect this information for business and commercial purposes as described above in How We Use
                        Your Data section. Information about which categories of third parties we share this information
                        with is described above in How We Disclose Personal Data.
                    </p>
                    <p>
                        California consumers can make a request related to the above-noted rights by emailing us at
                        dpo@mocha.global.
                    </p>
                    <h1 id="your-rights-under-the-gdpr-if-you-are-a-data-subject-in-the-eu">
                        Your Rights Under the GDPR (If You Are a Data Subject in the EU)
                    </h1>
                    <p>
                        Under the law, you are granted six fundamental rights when data about you is being processed. We
                        will ensure to reach out without undue delay (no more than 30 days) when you submit a request
                        related to exercising your data subject rights. Although uncommon, when applicable for official
                        or legal reasons we may be unable to address the specific request you make related to your
                        rights.
                    </p>
                    <p>
                        Also, if a request is manifestly unfounded or excessive, in particular, because of their
                        repetitive character, we may either:
                    </p>
                    <ul>
                        <li>
                            <p>
                                charge a reasonable fee taking into account the administrative costs of providing the
                                information or communication or taking the action requested; or
                            </p>
                        </li>
                        <li>
                            <p>refuse to act on the request.</p>
                        </li>
                    </ul>
                    <p>
                        In this scenario will we inform you of the reasons we believe your request is manifestly
                        unfounded or excessive character of the request.
                    </p>
                    <p>As a data subject, you have the right to:</p>
                    <p>
                        a) The right to request confirmation of whether we process Personal Data relating to you, and if
                        so, to request a copy of that Personal Data;
                    </p>
                    <p>
                        b) The right to request that we rectify or update your Personal Data that is inaccurate,
                        incomplete, or outdated;
                    </p>
                    <p>
                        c) The right to request that we erase your Personal Data in certain circumstances provided by
                        law;
                    </p>
                    <p>
                        d) The right to request that we restrict the use of your Personal Data in certain circumstances,
                        such as while we consider another request that you have submitted (including a request that we
                        make an update to your Personal Data);
                    </p>
                    <p>
                        e) The right to request that we export your Personal Data that we hold to another company, where
                        technically feasible;
                    </p>
                    <p>
                        Where the processing of your Personal Data is based on your previously given consent, you have
                        the right to withdraw your consent at any time; and/or:
                    </p>
                    <p>In some cases, you may also have the right to object to the processing of your Personal Data.</p>
                    <p>To exercise your data protection rights please contact our DPO at dpo@mocha.global.</p>
                    <p>
                        We will comply with your request to the extent required by applicable law. We will not be able
                        to respond to a request if we no longer hold your Personal Data. If you feel that you have not
                        received a satisfactory response from us, you may have the right under applicable laws to
                        consult with the data protection authority in your country.
                    </p>
                    <h1 id="cookies">Cookies</h1>
                    <p>
                        Cookies are small text files placed on a Device when the Device is used to visit the Service or
                        the Site. We may place cookies or similar files on your Device for security purposes, to
                        facilitate site navigation and to personalize your experience while using the Service, such as
                        showing you specific offer(s) that we think will be of interest to you.
                    </p>
                    <p>
                        Cookies allow us to collect technical and navigational information, such as browser type, time
                        spent on the Service and pages visited. Cookies may include a Device Identifier along with
                        information about the particular type of content in which you have shown interest. Cookies also
                        allow us to select which content is most likely to appeal to you and display that content while
                        you are using the Service.
                    </p>
                    <p>
                        When interacting with the Service, if you click on a link to one of our promoted offer(s), the
                        customer sponsoring the promoted link may place a cookie on your Device as well, in which case
                        you will be subject to their privacy policy. To find out more about our cookies you can access
                        our Cookie Policy.
                    </p>
                    <p>
                        <strong>Other Sites</strong>
                    </p>
                    <p>
                        The Service may contain links to other sites that we do not own or operate. This includes links
                        from customers, sponsors and partners We do not control, recommend or endorse and are not
                        responsible for these sites or their content, products, services or privacy policies or
                        practices. These other web sites may send their own cookies to your Device, they may
                        independently collect data or solicit Personal Information and may or may not have their own
                        published privacy policies. You should also independently assess the authenticity of any site
                        that appears or claims that it is a part of the Service.
                    </p>
                    <p>
                        <strong>Changes</strong>
                    </p>
                    <p>
                        Please note, we may modify information presented via the Service and/or this Privacy Policy from
                        time to time. You should check back here periodically to see if the Privacy Policy has been
                        updated. We will always show the date of the latest modification of the Privacy Policy at the
                        bottom of the page so you can tell when it was last revised.
                    </p>
                    <p>This policy was last revised Oct 2022</p>

                    <p>
                        <strong>COOKIE POLICY</strong>
                    </p>
                    <p>Effective as of Oct 2022</p>
                    <p>
                        Mocha Holdings, LLC (“Mocha,” “we,” “us,” or “our”) use cookies on the site apexprivacy.com
                        (“Site”). This Cookie Policy applies to the use of{' '}
                        <a href="http://www.searchbrightly.com">www.searchbrightly.com</a> and to our use of personal
                        information gathered.
                    </p>
                    <h1 id="what-are-cookies">What are cookies?</h1>
                    <p>
                        Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file
                        is stored in your web browser and allows the Service or third party to recognize you and make
                        your next visit easier and the Service more useful to you.
                    </p>
                    <p>
                        Cookies can help a website arrange content to match your preferred interests more quickly but
                        cannot be used by themselves to identify you.
                    </p>
                    <h1 id="why-do-we-use-cookies">Why do we use cookies?</h1>
                    <p>
                        Cookies perform many different tasks, such as optimizing your browsing through pages,
                        remembering your preferences, and personalizing your browsing experience. They even help ensure
                        that the ads and other content you access online are relevant to you. In addition, they are used
                        to collect statistical data.
                    </p>
                    <h1 id="how-long-do-cookies-last">How long do cookies last?</h1>
                    <p>In general, cookies can be “session” or “persistent” cookies.</p>
                    <p>
                        Session Cookies are temporary cookies that are not stored on your computer or mobile device, but
                        they are created temporarily in your browser’s subfolder while you are visiting a website and
                        they are not stored on your computer or mobile device. An example, a session cookie is also used
                        to remember your language preference when viewing the site. These session cookies are erased
                        when you close your browser, or after 20 minutes of inactivity therefore once you leave the
                        site, the session cookie is deleted.
                    </p>
                    <p>
                        Persistent cookies are those placed on your computer or mobile device for a predefined length of
                        time when you visit this site, and they remain in your browser’s subfolder and are activated
                        again once you visit the website that created that particular cookie. A persistent cookie
                        remains in the browser’s subfolder for the duration period set within the cookie’s file.
                    </p>
                    <h1 id="first-and-third-party-cookies">First and Third-party cookies</h1>
                    <p>
                        First-party cookies are cookies that belong to Mocha Global, third-party cookies are cookies
                        that another party places on your device through our Site. Third-party cookies may be placed on
                        your device by someone providing a service for Mocha Global, for example, to help us understand
                        how our service is being used. Third-party cookies may also be placed on your device by our
                        business partners so that they can use them to advertise products and services to you elsewhere
                        on the internet. The types of cookies used by us and our partners in connection with the Site
                        are explained below.
                    </p>
                    <h1 id="what-cookies-are-used-by-mocha-global">What cookies are used by Mocha Global?</h1>
                    <ol type="1">
                        <li>
                            <p>Strictly Necessary Cookies</p>
                        </li>
                        <li>
                            <p>Functionality Cookies</p>
                        </li>
                        <li>
                            <p>Performance Cookies</p>
                        </li>
                        <li>
                            <p>Targeting Cookies</p>
                        </li>
                    </ol>
                    <h1 id="cookie-table">Cookie Table</h1>
                    <p>The table provides a list of cookies used by apexprivacy.com:</p>
                    <p>
                        <strong>Category: Strictly Necessary Cookies</strong>
                    </p>
                    <table>
                        <tbody>
                            <tr className="odd">
                                <td>Name</td>
                                <td>Provider</td>
                                <td>Description</td>
                                <td>Expiry</td>
                                <td>Type</td>
                            </tr>
                            <tr className="even">
                                <td>cookiecookie</td>
                                <td>searchbrightly.com</td>
                                <td>Unclassified</td>
                                <td>Session</td>
                                <td>First Party</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        <strong>Category: Performance Cookies</strong>
                    </p>
                    <table>
                        <tbody>
                            <tr className="odd">
                                <td>Name</td>
                                <td>Provider</td>
                                <td>Description</td>
                                <td>Expiry</td>
                                <td>Type</td>
                            </tr>
                            <tr className="even">
                                <td>_ga</td>
                                <td>searchbrightly.com</td>
                                <td>
                                    Registers a unique ID that is used to generate statistical data on how the visitor
                                    uses the website.
                                </td>
                                <td>2 years</td>
                                <td>First Party</td>
                            </tr>
                            <tr className="odd">
                                <td>_gat</td>
                                <td>searchbrightly.com</td>
                                <td>Used by Google Analytics to throttle request rate</td>
                                <td>1 day</td>
                                <td>First Party</td>
                            </tr>
                            <tr className="even">
                                <td>_gid</td>
                                <td>searchbrightly.com</td>
                                <td>
                                    Registers a unique ID that is used to generate statistical data on how the visitor
                                    uses the website
                                </td>
                                <td>1 day</td>
                                <td>First Party</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        <strong>
                            <br />
                            Category: Targeting Cookies
                        </strong>
                    </p>
                    <table>
                        <tbody>
                            <tr className="odd">
                                <td>Name</td>
                                <td>Provider</td>
                                <td>Description</td>
                                <td>Expiry</td>
                                <td>Type</td>
                            </tr>
                            <tr className="even">
                                <td>
                                    <p>collect</p>
                                </td>
                                <td>google-analytics.com</td>
                                <td>
                                    Used to send data to Google Analytics about the visitor's device and behavior.
                                    Tracks the visitor across devices and marketing channels
                                </td>
                                <td>Session</td>
                                <td>Third Party (Pixel)</td>
                            </tr>
                            <tr className="odd">
                                <td>__anon_id</td>
                                <td>myshoppingfeed.com</td>
                                <td>
                                    Collects visitor data related to the user's visits to the website, such as the
                                    number of visits, average time spent on the website and what pages have been loaded,
                                    with the purpose of displaying targeted ads.
                                </td>
                                <td>Session</td>
                                <td>First Party</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        <strong>How Can I Control Cookie Preferences?</strong>
                    </p>
                    <p>
                        You can always disable cookies altogether in the browser settings or you can delete cookies from
                        the browser and disable all targeted advertising and communication based on your previous visits
                        to our website.
                    </p>
                    <p>
                        What are your options regarding cookies if you’d like to delete cookies or instruct your web
                        browser to delete or refuse cookies, please check our instructions on how to disable cookies on
                        your chosen browser as per below:
                    </p>
                    <ul>
                        <li>
                            <p>
                                Chrome{' '}
                                <em>
                                    <a href="https://support.google.com/chrome/?hl=en#topic=9796470">
                                        <u>https://support.google.com/chrome/?hl=en#topic=9796470</u>
                                    </a>
                                </em>
                            </p>
                        </li>
                        <li>
                            <p>
                                Firefox{' '}
                                <em>
                                    <a href="https://support.mozilla.org/en-US/products/firefox/privacy-and-security">
                                        <u>https://support.mozilla.org/en-US/products/firefox/privacy-and-security</u>
                                    </a>{' '}
                                </em>
                            </p>
                        </li>
                        <li>
                            <p>
                                Safari{' '}
                                <em>
                                    <a href="https://support.apple.com/en-us/HT201265">
                                        <u>https://support.apple.com/en-us/HT201265</u>
                                    </a>
                                </em>
                            </p>
                        </li>
                        <li>
                            <p>
                                Internet Explorer{' '}
                                <em>
                                    <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
                                        <u>
                                            https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
                                        </u>
                                    </a>
                                </em>
                            </p>
                        </li>
                    </ul>
                    <p>
                        Please note, however, that if you delete cookies or refuse to accept them, you might not be able
                        to use all of the features we offer, such as you will not be able to store your preferences and
                        our Service might not display properly.
                    </p>
                    <h1 id="key-tips-for-safe-and-responsible-cookie-based-web-browsing">
                        Key tips for safe and responsible cookie-based Web browsing
                    </h1>
                    <p>
                        Due to their flexibility and the fact that our service uses cookies by default, cookies are
                        almost unavoidable. Here are some tips you can use to ensure worry-free cookie-based browsing:
                    </p>
                    <p>
                        Customise your browser’s cookie settings to reflect your comfort level with cookie security or
                        use our guide to delete cookies.
                    </p>
                    <p>
                        If you are very comfortable with cookies and you are the only person using your computer, you
                        may want to set long expiration time frames for storing your personal access information and
                        browsing history.
                    </p>
                    <p>
                        If you share access on your computer, you may want to set your browser to clear private browsing
                        data every time you close your browser. While not as secure as rejecting cookies outright, this
                        option lets you access cookie-based websites while deleting any sensitive information after your
                        browsing session.
                    </p>
                    <p>
                        Make sure your browser is updated and if you haven’t already, set your browser to update
                        automatically. This eliminates security vulnerabilities caused by outdated browsers. Many
                        cookie-based exploits are based on exploiting older browsers’ security shortcomings.
                    </p>
                    <p>
                        <strong>Where can you find more information about cookies?</strong>
                    </p>
                    <p>You can learn more about cookies and the following third-party websites:</p>
                    <p>
                        AllAboutCookies:{' '}
                        <a href="http://www.allaboutcookies.org">
                            <u>http://www.allaboutcookies.org</u>
                        </a>
                    </p>
                    <p>
                        WhatAreCookies:{' '}
                        <a href="http://www.whatarecookies.com">
                            <u>http://www.whatarecookies.com</u>
                        </a>
                    </p>
                    <p>
                        <strong>Contact Us:</strong>
                    </p>
                    <p>
                        If you have any questions or comments about this Cookie Policy or the Service provided by Mocha
                        Global, please contact us at:{' '}
                        <a href="mailto:dpo@mocha.global?subject=Website%20Inquiry">dpo@mocha.global</a>
                    </p>
                </div>
                <footer>
                    <Link to="/">Search Brightly</Link> | <Link to="/privacy">Privacy Policy</Link> |{' '}
                    <Link to="/terms">Terms of Service</Link> | <Link to="/contact">Contact Us</Link>
                </footer>
            </div>
        </>
    );
};

export default Privacy;
