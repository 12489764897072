import { useEffect } from 'react';
import { Router, useLocation } from '@reach/router';
import Home from '@/pages/Home';
import Privacy from '@/pages/Privacy';
import Terms from '@/pages/Terms';
import Contact from '@/pages/Contact';
import { useAnalytics } from 'use-analytics';
import './App.css';

function App() {
    const location = useLocation();
    const { page } = useAnalytics();

    useEffect(() => {
        const { search, href } = location;
        const searchParams = new URLSearchParams(search);
        searchParams.delete('q');
        const sanitizedSearch = searchParams.toString().length ? `?${searchParams}` : '';
        const sanitizedHref = `${href.split('?')[0]}${sanitizedSearch}`;
        page({ search: sanitizedSearch, url: sanitizedHref });
    }, [location, page]);

    return (
        <Router>
            <Home path="/" />
            <Privacy path="/privacy" />
            <Terms path="/terms" />
            <Contact path="/contact" />
        </Router>
    );
}

export default App;
