import React from 'react';
import { Link } from '@reach/router';
import { Helmet } from 'react-helmet';

const Terms = () => {
    return (
        <>
            <Helmet>
                <title>Search Brightly Terms and Conditions</title>
            </Helmet>
            <div className="container">
                <div className="header">
                    <h1 id="logo">Search Brightly</h1>
                </div>
                <div className="content">
                    <h2>Terms and Conditions</h2>
                    <p>
                        Searchbrightly.com (the "Site" or “We” or “Us”), is published and maintained as a service to its
                        visitors. We provide targeted content through a network of topically themed websites and we
                        further offer our visitors who register the Site, to engage and contribute to the Site by adding
                        content of their own.
                    </p>
                    <p>
                        The Site may at any time change these Terms of Service. Therefore, upon each time you may visit
                        this Site you should review Terms of Service for your understanding of its conditions. By using
                        this Site, you consent to its Terms of Service, which are enforced at all times. If you do not
                        agree to the Terms of Service, please stop use of the Site immediately. Users of the Site are
                        not warranted that their use shall be uninterrupted or free of any errors. Management of the
                        Site may at any time and on its own discretion without prior notice discontinue, suspend, or
                        remove and change the contents of the Site. These changes are to be defined below.
                    </p>
                    <p>
                        These Terms of Service ("Terms") is the accesses and Terms of your Use in the website. Using
                        this Site is an adult or a parent or and/or guardian of any minor who approved the use of the
                        Web Site and all those who take responsibility for a minor for using this Site.
                    </p>
                    <p>
                        We keep the right to update these Terms from time to time and will post the updated Terms on the
                        Site or provide you with a notice via email. We will also update the "last updated on" date.
                        Make sure you read these Terms carefully.
                    </p>
                    <h3>Services</h3>
                    <p>
                        "Services" shall mean items, including without limitation the following: products, promotional
                        offers and service listings provided by merchants on Searchbrightly.com and possibly on other
                        affiliated websites and/or applications as well as shopping content provided by
                        Searchbrightly.com and/or third parties. We often provide you listings based on your search in
                        the search box or through other means to enable you to compare products, prices and stores. The
                        Services try to provide you with the latest available shopping information which allows you, the
                        user, to compare prices of Services provided by merchants that we display on Searchbrightly.com.
                    </p>
                    <p>
                        Searchbrightly.com introduces you to various merchants of goods and/or services online. We use
                        reasonable efforts to ensure the availability of the information and content on the Site,
                        including product information and pricing. However, you should note that merchants have the
                        ability to change or terminate their service at any time, including, but not limited to, the
                        coupon or offer code, the expiration date, their pricing or product availability. Because the
                        Services are composed of content not always offered by us, we do not control and guarantee (i)
                        the pricing or availability of products, services or promotions provided by the merchant; (ii)
                        the availability or technical capabilities of the merchant websites or links to their websites;
                        (iii) the quality, safety or legality of items, promotions or services available on our Site or
                        on merchant websites or sites of third parties; (iv) the terms of Services made by the merchant;
                        and (v) the truth or accuracy or legality of the content on our site from us, such merchants or
                        third parties. We are not liable or responsible for the Services, or for actions you might take
                        in reliance on the Services.
                    </p>
                    <p>
                        Nothing contained in any of the Services is an offer, guarantee or promise. We do not guarantee
                        the price, terms, availability, and other details of any of the Services.
                    </p>
                    <p>
                        With respect to the Services, we are not involved in any transactions that you consummate
                        between you and any of the merchants whose Services are listed on our Site. We are also not
                        responsible for, and do not guarantee the price, terms, performance or any aspect of the
                        Services nor any advertisement by a third party displayed on our Site. If you have a dispute
                        with one or more merchants in connection with the Services or the Site, you agree to release and
                        hereby release us from claims, demands and damages (actual and consequential) of every kind and
                        nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of
                        or in any way connected with such disputes. If applicable, you waive California Civil Code
                        Section 1542, which says: "a general release does not extend to claims which the creditor does
                        not know or suspect to exist in his favor at the time of executing the release, which if known
                        by him must have materially affected his settlement with the debtor."
                    </p>
                    <h3>Linked Content</h3>
                    <p>
                        The content displayed or linked to by us is developed by people over whom we exercise no control
                        and we cannot and do not screen the sites before including them in the Site. We cannot guarantee
                        that any content will not contain unintended or objectionable content and assume no
                        responsibility for the content of any site included in the Site, and we do not assume
                        responsibility for the goods, services, or information provided by such sites.
                    </p>
                    <h3>Personal Use Only</h3>
                    <p>
                        Searchbrightly.com Services are made available for your personal, non-commercial use only. You
                        may not take the Site’s different content and display them or mirror any home page or results
                        pages on your Website.
                    </p>
                    <h3>Indemnification</h3>
                    <p>
                        You agree to indemnify and to hold us("Indemnifying Party") harmless, and indemnify us from and
                        against any expenses (including attorney fees) arising from claims of third-parties made against
                        or incurred by the Indemnified Party as a result of negligence, misrepresentation, error or
                        omission on the part of the Indemnifying Party or any employee, agent or representative of the
                        Indemnifying Party, or any breach of this Agreement by the Indemnifying Party, regardless of
                        whether such claims were foreseeable by the Indemnified Party. The Indemnified Party will have
                        the right to participate, at its expense, in the defense of any claim covered hereunder with
                        counsel of its own choosing. The Indemnified Party shall give the Indemnifying Party prompt
                        notice of any such claim and shall reasonably cooperate with the Indemnifying Party and its
                        counsel in the defense of such claim.
                    </p>
                    <h3>WARRANTIES</h3>
                    <p>
                        WE DISCLAIMALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO NONINFRINGEMENT,
                        SATISFACTORY QUALITY, MERCHANTABILITY AND FITNESS FOR ANY PURPOSE, AND EXCEPT AS EXPLICITLY SET
                        FORTH IN THIS AGREEMENT, ALL CONTENT ON THE SITE IS PROVIDED "AS-IS". WE DONOT WARRANT THE
                        ACCURACY OR USEFULNESS OF SEARCH RESULTS NOR THE AVAILABILITY OR ACCEBILITY OF THE SERVICE.
                    </p>
                    <h3>Limitation of Liability</h3>
                    <p>
                        YOU UNDERSTAND AND AGREE THAT THE WEBSITE, CONTENT AND PSEARCH RESULTSARE PROVIDED ON AN "AS IS"
                        AND "AS AVAILABLE" BASIS. YOU EXPRESSLY AGREE THAT USE OF THE WEBSITE, CONTENT AND P SEARCH
                        RESULTSIS AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMISSIBLE UNDERTHE APPLICABLE LAW,
                        WEDISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO,
                        WARRANTIES OF TITLE AND IMPLIED WARRANTIES OF MERCHANTIBILITY OR FITNESS FOR A PARTICULAR
                        PURPOSE. WITHOUT LIMITING THE FOREGOING, NEITHER WENORANY OF OURAFFILIATES, NOR ANY OF
                        OUROFFICERS, DIRECTORS, LICENSORS, EMPLOYEES OR REPRESENTATIVES REPRESENT OR WARRANT (i) THAT
                        THE SERVICE/WEBSITE, INCLUDING ITS CONTENT, WILL MEET YOUR REQUIREMENTS OR BE ACCURATE,
                        COMPLETE, RELIABLE, OR ERROR-FREE; (ii) THAT THE SERVICE/WEBSITE WILL ALWAYS BE AVAILABLE OR
                        WILL BE UNINTERRUPTED, ACCESSIBLE, TIMELY, OR SECURE; (iii) THAT ANY DEFECTS WILL BE CORRECTED,
                        OR THAT THE SERVICE/WEBSITE WILL BE FREE FROM VIRUSES, "WORMS," "TROJAN HORSES" OR OTHER HARMFUL
                        PROPERTIES; (iv) THE ACCURACY, RELIABILITY, TIMELINESS, OR COMPLETENESS OF ANY REVIEW,
                        RECOMMENDATION, OR OTHER MATERIAL PUBLISHED OR ACCESSIBLE ON OR THROUGH THE SERVICE/WEBSITE; (v)
                        ANY IMPLIED WARRANTY ARISING FROM COURSE OF DEALING OR USAGE OF TRADE; AND (vi) THAT THE
                        SERVICE/WEBSITE IS NONINFRINGING. WEAND OURAFFILIATES HEREBY DISCLAIM, AND YOU HEREBY WAIVE AND
                        RELEASE USAND OURAFFILIATES FROM, ANY AND ALL OBLIGATIONS, LIABILITIES, RIGHTS, CLAIMS, OR
                        REMEDIES IN TORT ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT OR THE SERVICE, WHETHER OR
                        NOT ARISING FROM THE NEGLIGENCE (ACTIVE, PASSIVE OR IMPUTED). SOME JURISDICTIONS DO NOT ALLOW
                        THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. YOU MAY ALSO
                        HAVE OTHER LEGAL RIGHTS, WHICH VARY FROM JURISDICTION TO JURISDICTION.
                    </p>
                    <h3>Amendments</h3>
                    <p>
                        We may amend this Agreement at any time by posting the amended terms on its Website. All amended
                        terms are automatically effective.
                    </p>
                    <h3>Choice of Law, Waiver, and Claims</h3>
                    <p>
                        This Agreement shall be governed by the laws of the State of Delaware without regard to its
                        conflict of law provisions. Our failure to exercise or enforce any right or provision of the
                        Agreement will not be deemed to be a waiver of such right or provision. If any provision of this
                        Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless
                        agree that the court should endeavor to give effect to the parties' intentions as reflected in
                        the provision, and the other provisions of this Agreement remain in full force and effect. You
                        agree that regardless of any statute or law to the contrary, any claim or cause of action by you
                        arising out of or related to use of the Services must be filed within one (1) year after such
                        claim or cause of action arose or be forever barred.
                    </p>
                    <h3>Miscellaneous</h3>
                    <p>
                        Nothing in these Terms creates any agency, employment, joint venture, or partnership
                        relationship between you and us. Except as may be expressly stated in these Terms, these Terms
                        constitute the entire agreement between us and you pertaining to the subject matter hereof. We
                        may assign our rights and obligations under these Terms and the Privacy Policy. You may not
                        assign your rights and obligations hereunder without our prior written consent.
                    </p>
                </div>
                <footer>
                    <Link to="/">Search Brightly</Link> | <Link to="/privacy">Privacy Policy</Link> |{' '}
                    <Link to="/terms">Terms of Service</Link> | <Link to="/contact">Contact Us</Link>
                </footer>
            </div>
        </>
    );
};

export default Terms;
